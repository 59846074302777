.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #0297d0;
}

.pdf-container {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.pdf-container .pdf-hide {
  display: none;
}
